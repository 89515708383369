//
//
//
//

export default {
	name: "Content",
	props: {
		content: {
			required: true,
		},
	},
};
