//
//
//
//
//
//

import Content from "~/components/Content"
export default {
	name: "landingpage",
	components: {
		Content,
	},
	async asyncData({ store, route, $md }) {
		await store.dispatch("content/fetchContentByKeys", {
			path: route.path,
		})
		const content = store.getters["content/getContent"](route.path);
		if (content) {
			const page = $md.render(content);
			return {
				page
			}
		}
	},
	head() {
		return {
			title: "VoteLog",
		}
	},
}
